<template>

  <div class="posts">

    <div class="ptl">{{ $t('journal_view_title_voting') }}</div>
    <div class="lst"> 
      <div v-for="(opt,ind) in data" class="vbox"> 
        <div class="con">
          <NuxtLink class="ttl" :to="opt.link">{{ opt.title }}</NuxtLink>
          <div class="desc">
            <div v-html="opt.description"></div>
            <div class="con vote_list">
              <div :class="'vit vote_' + opt.vote_id + ' ' + (opt.is_user_vote ? 'voted' : '')"> 
              
                <div class="vote_btn">
                  <div class="cnt">
                    {{ opt.cnt_votes }}
                  </div>
                  <div class="tx" @click="vote(opt)">
                    {{ $plurals.get('vote', opt.cnt_votes) }}
                  </div>
                </div>
              
              </div>

            </div>

          </div>
          <div class="stat">
            <div class="it">
              {{ $dayjs.utc(opt.view_date).fromNow() }}
            </div>
            <div class="it" v-if="opt.cnt_comments">
              {{ $plurals.get('comment', opt.cnt_comments) }}
            </div>
          </div>
        </div>
        
      </div>
    </div>

  </div>

</template>

<script setup>

const { $api } = useNuxtApp()

const props = defineProps({
  data: {
    type: Array,
    required: true
  }
})

const vote = function(vote){
  $api.postJournalVote(vote.vote_id)
  .then((res) => {
     vote.cnt_votes += parseInt(res.cnt);
  })
  .catch(function (error) {

  });
}
 
</script>

<style scoped>
 
 
 .journal .ptl{
    width: 100%;
    text-transform: uppercase;
    margin-bottom: 1.5rem;
    font-size: 1.4rem;
    font-weight: normal;
  }

  
  .journal .ptla{
  text-transform: initial;
  color: darkgray;
  text-decoration: underline;
  font-size: 1.2rem;
  margin-left: 7px;
  display: inline-block;
  cursor: pointer;
  }
  
 .vote_list{

}

.vote_list .vit{
    display: flex;
    align-items: flex-start;
    margin-bottom: 1rem;
}

.vbox .vit{
    float: right;
    display: inline-block;
    width: auto;
    margin-right: auto;
    margin-top: 1rem;
}
.vote_list .vit .nm{
    font-weight: 500;
    margin-right: 2rem;
}
.vote_list .vit .vote_btn{
    display: flex;
    margin-left: auto;
    border: 1px var(--un-primary-back-color) solid;
    border-radius: 3px; 
    /* padding: 3px 7px; */
    margin-top: -2px;
}
.vote_list .vit .vote_btn .cnt{
    padding: 1px 7px;
    color: var(--un-primary-back-color);
    border-right: 1px var(--un-primary-back-color) solid;
    min-width: 29px;
    text-align: center;
}
.vote_list .vit .vote_btn .tx{
    padding: 1px 13px;
    color: var(--un-primary-back-color);
    cursor:pointer;
}
.vote_list .vit.voted .vote_btn .tx,
.vote_list .vit .vote_btn .tx:hover{

    color: #ffffff;
    background-color: var(--un-primary-back-color);
}




@container pb (max-width: 768px) {
    .vbox .vit{ 
        float: none;
        margin-top: auto;
        margin-left: 0;
        margin-right: auto;
    }
}

.vbox{
    display: flex;
    flex-direction: column;
    margin-bottom: 2rem;
}
.vbox .cvr{
    width: 12rem;
    display: flex;
    height: 12rem;
    overflow: hidden;
    justify-content: center;
    flex-shrink: 0;
    margin-right: 2rem;
    border-radius: 5px;
}
.vbox .cvr .cvri{
    width: auto;
    height: 100%;
}
.vbox .con{
    width: 100%;
    display: flex;
    flex-direction: column;
    /* padding: 1rem 0; */
}
.vbox .con .ttl{
    font-size: 1.3rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
}
.vbox .con .cat{
    font-size: 1rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
    color: #a2a2a2;
    text-transform: uppercase;
    font-weight: bold;
    margin-right: 2.5rem;
}
.vbox .con .desc{
    color: gray;
    font-size: 1rem; 
}
.vbox .con .stat{
    color: gray;
    font-size: 1rem;
    margin-top: auto;
    display: flex;
    flex-wrap: wrap;
}
.vbox .con .stat .it{
    margin-right: 1rem;
}


/* IN LIST */
.lst .vbox{
    /* width: calc(50% - 1rem); */
}
/*  */




@container pb (max-width: 1300px) {
    .lst .vbox{
        width: 100%;
    }
}


/* TOP MAIN */
.fst .vbox{flex-direction: column;}
.fst .vbox .cvr{width: 100%;height: 300px;}
.fst .vbox .cvr .cvri{ 
}
.fst .vbox .con{ 
}
.fst .vbox .con .ttl{font-size: 1.7rem;line-height: 2rem; padding-top:1rem}
.fst .vbox .con .cat{display:none;}
.fst .vbox .con .desc{ 
}
.fst .vbox .con .stat{}
.fst .vbox .con .stat .it{}

/*  */


/* TOP THREE */
@container pb (max-width: 900px) {
    .mdl .vbox{}
    .mdl .vbox .cvr{
        width: 10rem;
        height: 10rem;
    }
    .mdl .vbox .cvr .cvri{ 
    }
    .mdl .vbox .con{justify-content: center;}
    .mdl .vbox .con .ttl{}
    .mdl .vbox .con .desc{display: none;}
    .mdl .vbox .con .stat{
        margin-top: 0;
        margin-top: 1rem;
    }
    .mdl .vbox .con .stat .it{}
}
/*  */

</style>
